import React from "react";
import { Nav } from "react-bootstrap";
import { AnchorLink } from "gatsby-plugin-anchor-links";

//import "./moreFeaturesLinks.scss";
import SectionHeader from "../../components/sectionHeader";

function MoreFeaturesLinks(props) {
 
  return (
    <div>
      <div className="view-more">
      <SectionHeader
          highlight={props.data.TitleHighlight}
          title={props.data.Title}
        />
      </div>

      
    </div>
  );
}

export default MoreFeaturesLinks;
